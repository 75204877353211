import { assertNonNullable } from 'cadenza/utils/custom-error';
import type { RepositoryName } from 'cadenza/repository/model/repository-name';
import { isValidRepositoryName } from 'cadenza/repository/model/repository-name-util';
import type { RepositoryItemName } from 'cadenza/repository/model/repository-item-name';
import type { ItemType } from 'cadenza/repository/model/item-type';

/**
 * Canonical serialization form of IRepositoryItemId
 * @see net/disy/cadenza/repository/api/IRepositoryItemId.java
 */
export interface RepositoryItemId<ITEM_TYPE extends ItemType = ItemType, ITEM_NAME extends RepositoryItemName = RepositoryItemName> {
  itemType: ITEM_TYPE;
  repositoryName: RepositoryName;
  itemName: ITEM_NAME;
}

export interface RepositoryItemIdDefinition<ITEM_ID extends RepositoryItemId> {
  itemType: ITEM_ID['itemType'];

  of(repositoryName: RepositoryName, itemName: ITEM_ID['itemName']): ITEM_ID;
}

/**
 * Tuple-based serialization form of IRepositoryItemId
 * @see net/disy/cadenza/repository/api/IRepositoryItemId.java
 * @see net/disy/cadenza/web/repository/AbstractRepositoryItemIdSerializer.java
 */
export type RepositoryItemIdTuple<T extends RepositoryItemName = RepositoryItemName> = [repositoryName: RepositoryName, itemName: T];

export function defineRepositoryItemId<ITEM_ID extends RepositoryItemId> (
  itemType: ITEM_ID['itemType'],
  factory: (repositoryName: RepositoryName, itemName: ITEM_ID['itemName']) => ITEM_ID =
  (repositoryName, itemName) => {
    const itemId = { itemType, repositoryName, itemName };
    return itemId as ITEM_ID;
  }
): RepositoryItemIdDefinition<ITEM_ID> {
  return {
    itemType,
    of: factory
  };
}

const REPOSITORY_ITEM_ID_URL_REGEX = '/repositories/(?<repositoryName>[^/]+)/[^/]+/(?<itemName>[^/]+)';

/** @return a [ RepositoryName, RepositoryItemName ] tuple, or throws an exception */
export function repositoryItemIdTupleFromUrl<T extends RepositoryItemIdTuple> () {
  const repositoryItemid = repositoryItemIdTupleFromUrlString<T>();
  assertNonNullable(repositoryItemid, 'No repository item id found');
  return repositoryItemid;
}

/**
 * @param id The RepositoryItemIdTuple or JSON object containing both
 * @return [ RepositoryName, RepositoryItemName ] tuple
 */
export function toIdArr (id: RepositoryItemIdTuple | { repositoryName: RepositoryName; id: RepositoryItemName }): RepositoryItemIdTuple {
  return Array.isArray(id)
    ? id
    : [ id.repositoryName, id.id ];
}

/** @return a [ RepositoryName, RepositoryItemName ] tuple, or `undefined` */
export function repositoryItemIdTupleFromUrlString<T extends RepositoryItemIdTuple> (url: string = location.pathname): T | undefined {
  const match = url.match(REPOSITORY_ITEM_ID_URL_REGEX);
  if (match) {
    const { repositoryName, itemName } = match.groups!;
    if (isValidRepositoryName(repositoryName)) {
      return [ repositoryName, itemName ] as T;
    }
  }
  return undefined;
}

export function repositoryItemIdEquals<T extends RepositoryItemIdTuple> (a: T, b: T) {
  const [ aRepositoryName, aItemName ] = a;
  const [ bRepositoryName, bItemName ] = b;
  return aRepositoryName === bRepositoryName && aItemName === bItemName;
}
